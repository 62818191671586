import React from "react";
import logo from "svg/logo.svg";

export default function Logo() {
  return (
    <img
      alt="ChatGPT logo"
      src={logo}
      style={{ width: "180px", height: "48px" }}
    />
  );
}
