import { Stack } from "@mui/material";
import React from "react";
import models from "svg/models.svg";
import models2 from "svg/models2.svg";
import NormalText from "./Text/NormalText";
import HeaderText from "./Text/HeaderText";

export default function ModelsHeader() {
  return (
    <Stack alignItems="center" spacing={1}>
      <img
        alt="features"
        src={models}
        style={{ width: "201px", height: "40px" }}
      />
      <HeaderText
        align="center"
        sx={{
          background: "linear-gradient(180deg, #FFFFFF, 75%, #555555)",
          backgroundClip: "text",
          fontSize: "3rem",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          color: "transparent",
        }}
      >
        Choose your favorite AI model
      </HeaderText>
      <NormalText
        sx={{
          maxWidth: "sm",
        }}
        align="center"
      >
        Access all major AI models in the same platform. We work tierlessly to ensure that 
        you can access the latest developments in AI.
      </NormalText>
      <img alt="models" src={models2} style={{ width: "100%"}} />
    </Stack>
  );
}
