import { Box } from "@mui/material";
import React from "react";

export default function FeatureContainer({ children, ...props }) {

  return (
    <Box
      {...props}
      sx={{
        borderRadius: "16px",
        background: "radial-gradient(circle at center, rgba(123, 232, 176, 0.01), rgba(123, 232, 176, 0.1))",
        padding: "32px",
        borderTop: "1px solid rgba(99, 133, 116, 0.3)",
        borderLeft: "1px solid rgba(99, 133, 116, 0.3)",
        borderRight: "1px solid rgba(23, 31, 27, 0.45)",
        borderBottom: "1px solid rgba(23, 31, 27, 0.45)",
        ...props.sx,
      }}
    >
      {children}
    </Box>
  );
}
