import { Grid2, Stack } from "@mui/material";
import ChatMessage from "components/ChatMessage";
import FeatureContainer from "components/FeatureContainer";
import FeatureHeader from "components/Text/FeatureHeader";
import NormalText from "components/Text/NormalText";
import React, { useEffect } from "react";
import bot from "svg/bot.svg";
import { motion, useInView, useAnimation } from "framer-motion";

// Animation settings
const messageVariants = {
  hidden: { opacity: 0, transform: "translateY(20px)" },
  visible: { opacity: 1, transform: "translateY(0)" },
};

export default function CreateBot() {
  const ref = React.useRef(null);
  const inView = useInView(ref, { threshold: 0.5 }); // Adjust threshold as needed
  const animationControls = useAnimation();

  useEffect(() => {
    if (inView) {
      animationControls.start("visible");
    } else {
      animationControls.start("hidden");
    }
  }, [inView, animationControls]);

  return (
    <FeatureContainer>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Stack spacing={2}>
            <FeatureHeader>Create your own bots</FeatureHeader>
            <NormalText>
              Personalize your AI interaction by creating custom bots to suit
              your needs. Provide instructions once so you don't need to keep
              explaining what you want.
            </NormalText>
          </Stack>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Stack spacing={3} ref={ref}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="center"
              pt={2}
            >
              <img alt="wizz" src={bot} style={{ width: "48px" }} />
              <NormalText>
                Wizz was created by a user to help them learn Chinese by
                breaking down sentences into words with pronunciation.
              </NormalText>
            </Stack>
            <motion.div
              initial="hidden"
              animate={animationControls}
              variants={messageVariants}
              transition={{ duration: 0.5 }}
            >
              <Stack direction="row" justifyContent="flex-end">
                <ChatMessage>I would like to eat beef noodles.</ChatMessage>
              </Stack>
            </motion.div>
            <motion.div
              initial="hidden"
              animate={animationControls}
              variants={messageVariants}
              transition={{ duration: 0.5, delay: 1 }}
            >
              <ChatMessage
                avatar={<img alt="wizz" src={bot} style={{ width: "32px" }} />}
              >
                I (我) - wǒ
                <br />
                would like (想) - xiǎng <br />
                to eat (吃) - chī <br />
                beef (牛肉) - niúròu <br />
                noodles (麵) - miàn <br />
                <br />
                Putting it together: <br />
                我想吃牛肉麵. <br />
                (Wǒ xiǎng chī niúròu miàn.)
              </ChatMessage>
            </motion.div>
          </Stack>
        </Grid2>
      </Grid2>
    </FeatureContainer>
  );
}
