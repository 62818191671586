import { Stack, Typography } from "@mui/material";
import { COLORS } from "colors";
import { FONTS } from "fonts";
import React from "react";
import pricing from "svg/pricing.svg";
import NormalText from "./Text/NormalText";
import HeaderText from "./Text/HeaderText";

export default function PricingHeader() {
  return (
    <Stack alignItems="center" spacing={1}>
      <img
        alt="pricing"
        src={pricing}
        style={{ width: "99px", height: "40px" }}
      />
      <HeaderText
        align="center"
        sx={{
          background: "linear-gradient(180deg, #FFFFFF, 75%, #555555)",
          backgroundClip: "text",
          fontSize: "3rem",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          color: "transparent",
        }}
      >
        Ultra cheap AI access
      </HeaderText>
      <NormalText
        sx={{
          maxWidth: "sm",
        }}
        align="center"
      >
        It costs most AI models less than one cent to process a message. By paying
        per message instead of using a subscription, you'll save on your monthly bill.
      </NormalText>
    </Stack>
  );
}
