import { Grid2, Stack } from "@mui/material";
import React from "react";
import { motion, useInView } from "framer-motion";
import BenefitsHeader from "./BenefitsHeader";
import Benefit from "./Benefit";
import PricingHeader from "./PricingHeader";

const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.5, // delay between each child appearing
      delayChildren: 0.5, // delay before the first child starts animating
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

function AnimatedBenefit({ title, text, text2 = "", delay = 0 }) {
  const ref = React.useRef(null);
  const inView = useInView(ref, { triggerOnce: true });

  return (
    <motion.div
      ref={ref}
      variants={itemVariants}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      transition={{ duration: 0.5, delay }}
    >
      <Benefit title={title} text={text} text2={text2} />
    </motion.div>
  );
}

export default function PricingSection() {
  const ref = React.useRef(null);
  const inView = useInView(ref, { triggerOnce: true });

  return (
    <Stack spacing={8}>
      <PricingHeader />
      <motion.div
        ref={ref}
        variants={containerVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
      >
        <Grid2 container spacing={6}>
          <Grid2 size={{ xs: 12, md: 4 }}>
            <AnimatedBenefit
              title="Low message cost"
              text={`
                With the average message costing less than one cent, 
                your monthly usage will be much lower than that of any AI subscription.
              `}
              text2={`
                We used Cheap-GPT to help us build it. 
                In total, all messages cost only $13.65.
              `}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 4 }}>
            <AnimatedBenefit
              delay={0.5}
              title="No subscription"
              text={`
                Save money with Cheap GPT. 
                Pay only for the messages you send, which means you'll save money compared to 
                traditional subscription models. Most users send only a few 
                messages each day, making our pay-per-message model a more 
                affordable option than any fixed subscription plan.
              `}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 4 }}>
            <AnimatedBenefit
              delay={1}
              title="Low processing costs"
              text={`
                We charge only a small fee per message on top of the API fee
                charged by the model provider.  
              `}
            />
          </Grid2>
        </Grid2>
      </motion.div>
    </Stack>
  );
}
