import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { COLORS } from "colors";
import { FONTS } from "fonts";
import { motion, useInView } from "framer-motion";
import TypingText from "./Text/TypingText";
import NormalText from "./Text/NormalText";

export default function AIBlock({
  blockIndex,
  currentBlock,
  setCurrentBlock,
  avatar,
  icon,
  children,
  animated = true,
  result,
  ...props
}) {
  const [generate, setGenerate] = useState(false);

  const ref = React.useRef(null);

  const isInView = useInView(ref);

  React.useEffect(() => {
    if (isInView && currentBlock === blockIndex) {
      const timeout = setTimeout(() => {
        setGenerate(true);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [currentBlock, blockIndex, isInView]);

  if (animated && generate) {
    return (
      <TypingText
        blockIndex={blockIndex}
        currentBlock={currentBlock}
        setCurrentBlock={setCurrentBlock}
      >
        {result}
      </TypingText>
    );
  }

  return (
    <Stack direction="row" spacing={2} {...props} ref={ref}>
      <Stack
        p={2}
        justifyContent="center"
        sx={{
          borderRadius: "16px",
          backgroundColor: COLORS.white,
          border: "1px solid rgba(255, 255, 255, 0.1)",
        }}
        component={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: isInView ? 1 : 0 }}
        transition={{ duration: 0.5 }}
      >
        <Typography
          fontWeight="bold"
          fontFamily={FONTS.fjalla}
          variant="caption"
        >
          AI Block
        </Typography>
        <Typography
          fontFamily={FONTS.geist}
          color={COLORS.black}
          fontSize="0.9rem"
        >
          {children}
        </Typography>
      </Stack>
    </Stack>
  );
}
