import { Box, Stack, Typography } from "@mui/material";
import { COLORS } from "colors";
import { FONTS } from "fonts";
import React from "react";

export default function ChatMessage({ avatar, icon, children, ...props }) {
  return (
    <Stack direction="row" spacing={2} {...props}>
      <Box sx={{ width: "32px" }}>{avatar}</Box>
      <Stack
        p={2}
        justifyContent="center"
        alignItems="center"
        direction="row"
        sx={{
          borderRadius: "16px",
          backgroundColor: COLORS.darkGray,
          border: "1px solid rgba(255, 255, 255, 0.1)",
        }}
        spacing={1}
      >
        <Typography
          fontFamily={FONTS.geist}
          color={COLORS.white}
          fontSize="0.9rem"
        >
          {children}
        </Typography>
        {icon && (
          <img
            alt="icon"
            src={icon}
            style={{
              width: "48px",
              alignSelf: "flex-end",
            }}
          />
        )}
      </Stack>
    </Stack>
  );
}
