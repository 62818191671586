import { Stack } from "@mui/material";
import ChatMessage from "components/ChatMessage";
import FeatureContainer from "components/FeatureContainer";
import HeaderText from "components/Text/HeaderText";
import NormalText from "components/Text/NormalText";
import React, { useEffect } from "react";
import fileDecoration from "svg/file-deco.png";
import mp3Icon from "svg/mp3.svg";
import docIcon from "svg/doc.svg";
import pdfIcon from "svg/pdf.svg";
import { motion, useInView, useAnimation } from "framer-motion";

// Define animation variants
const messageVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

export default function FileSupport() {
  const ref = React.useRef(null);
  const inView = useInView(ref, { threshold: 0.5 }); // Adjust threshold as needed
  const animationControls = useAnimation();

  useEffect(() => {
    if (inView) {
      animationControls.start("visible");
    } else {
      animationControls.start("hidden");
    }
  }, [inView, animationControls]);

  return (
    <FeatureContainer
      sx={{
        position: "relative",
        height: "512px",
      }}
    >
      <Stack
        spacing={2}
        sx={{
          position: "relative",
          height: "100%",
        }}
      >
        <HeaderText>Multi-Format Attachments</HeaderText>
        <NormalText>
          Support for file attachments of all types, including URLs, audio
          files, PDFs, and documents. Easily integrate and share information for
          enhanced productivity.
        </NormalText>
        <Stack
          spacing={2}
          justifyContent="center"
          sx={{
            flexGrow: 1,
          }}
          ref={ref}
        >
          <motion.div
            initial="hidden"
            animate={animationControls}
            variants={messageVariants}
            transition={{ duration: 0.5 }}
          >
            <ChatMessage icon={pdfIcon}>
              Give me an outline for this file.
            </ChatMessage>
          </motion.div>

          <motion.div
            initial="hidden"
            animate={animationControls}
            variants={messageVariants}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            <ChatMessage icon={docIcon}>
              How can I improve this business model?
            </ChatMessage>
          </motion.div>

          <motion.div
            initial="hidden"
            animate={animationControls}
            variants={messageVariants}
            transition={{ duration: 0.5, delay: 1 }}
          >
            <ChatMessage icon={mp3Icon}>
              Summarize this podcast for me.
            </ChatMessage>
          </motion.div>
        </Stack>
      </Stack>
      <img
        alt="decoration"
        src={fileDecoration}
        style={{
          position: "absolute",
          left: "0px",
          bottom: "0px",
        }}
      />
    </FeatureContainer>
  );
}
