import { Grid2, Stack } from "@mui/material";
import FeatureContainer from "components/FeatureContainer";
import FeatureHeader from "components/Text/FeatureHeader";
import NormalText from "components/Text/NormalText";
import React, { useEffect } from "react";
import bot1 from "img/bot1.png";
import bot2 from "img/bot2.png";
import "diff.css";
import { motion, useInView, useAnimation } from "framer-motion";
import { diffString } from "diff";
import { FONTS } from "fonts";

// Animation settings
const messageVariants = {
  hidden: { opacity: 0, transform: "translateY(20px)" },
  visible: { opacity: 1, transform: "translateY(0)" },
};

const text1 = `
Subscrptions are often maketed as convnient, but they can trap users into ongoing paymnts for services they may not frequently use, making it easy for companies to gnerate contnuous revenue. By offering lower upfront costs contrasted with long-term commitments, companies entce users into seemingly economical deals that can ultimatly result in spending more than anticipated.
`;

const text2 = `
Subscriptions are often marketed as convenient, but they can trap users into ongoing payments for services they may not frequently use, making it easy for companies to generate continuous revenue. By offering lower upfront costs contrasted with long-term commitments, companies entice users into seemingly economical deals that can ultimately result in spending more than anticipated.
`;

export default function CustomTools() {
  const ref = React.useRef(null);
  const inView = useInView(ref, { threshold: 0.5 }); // Adjust threshold as needed
  const animationControls = useAnimation();

  useEffect(() => {
    if (inView) {
      animationControls.start("visible");
    } else {
      animationControls.start("hidden");
    }
  }, [inView, animationControls]);

  return (
    <FeatureContainer>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Stack spacing={2}>
            <FeatureHeader>Create your own AI editing tools</FeatureHeader>
            <NormalText>
              Personalize your text editor by creating custom AI edition tools
              that are perfectly tailored to your specific needs.
            </NormalText>
          </Stack>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Stack spacing={3} ref={ref}>
            <Stack>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="center"
                pt={2}
              >
                <img alt="wizz" src={bot1} style={{ width: "48px" }} />
                <NormalText>
                  Dejarg was created by a user to remove commonly used AI words
                  and make text sound more natural.
                </NormalText>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="center"
                pt={2}
              >
                <img alt="wizz" src={bot2} style={{ width: "48px" }} />
                <NormalText>
                  Gram was created by a user to proofread text and improve
                  writing in general.
                </NormalText>
              </Stack>
            </Stack>
            <motion.div
              initial="hidden"
              animate={animationControls}
              variants={messageVariants}
              transition={{ duration: 0.5, delay: 1 }}
            >
              <Stack spacing={-2}>
                <div
                  style={{
                    color: "white",
                    position: "relative",
                    fontFamily: FONTS.geist,
                  }}
                  dangerouslySetInnerHTML={{ __html: diffString(text1, text2) }}
                />
                <Stack direction="row" justifyContent="flex-end">
                  <img alt="botdiff" src={bot2} style={{ width: "32px" }} />
                </Stack>
              </Stack>
            </motion.div>
          </Stack>
        </Grid2>
      </Grid2>
    </FeatureContainer>
  );
}
