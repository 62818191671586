import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function SoundWave() {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });
  
  const rects = [
    { x: "8", y: "36", width: "10", height: "17", rx: "5", fill: "url(#paint0)" },
    { x: "26", y: "28", width: "10", height: "33", rx: "5", fill: "url(#paint1)" },
    { x: "44", y: "23", width: "10", height: "43", rx: "5", fill: "url(#paint2)" },
    { x: "62", y: "33", width: "10", height: "23", rx: "5", fill: "url(#paint3)" },
    { x: "80", y: "17", width: "10", height: "55", rx: "5", fill: "url(#paint4)" },
    { x: "98", y: "33", width: "10", height: "23", rx: "5", fill: "url(#paint5)" },
    { x: "116", y: "23", width: "10", height: "43", rx: "5", fill: "url(#paint6)" },
    { x: "134", y: "9", width: "10", height: "71", rx: "5", fill: "url(#paint7)" },
    { x: "152", y: "0", width: "10", height: "89", rx: "5", fill: "url(#paint8)" },
    { x: "170", y: "28", width: "10", height: "33", rx: "5", fill: "url(#paint9)" },
    { x: "188", y: "23", width: "10", height: "43", rx: "5", fill: "url(#paint10)" },
    { x: "206", y: "33", width: "10", height: "23", rx: "5", fill: "url(#paint11)" },
    { x: "224", y: "17", width: "10", height: "55", rx: "5", fill: "url(#paint12)" },
    { x: "242", y: "36", width: "10", height: "17", rx: "5", fill: "url(#paint13)" },
    { x: "260", y: "40", width: "10", height: "9", rx: "4.5", fill: "url(#paint14)" },
  ];

  return (
    <svg
      ref={ref}
      width="270"
      height="89"
      viewBox="0 0 270 89"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {rects.map((rect, index) => (
        <motion.rect
          key={index}
          initial={{ scaleY: 0 }}
          animate={{ scaleY: inView ? 1 : 0 }}
          transition={{
            delay: index * 0.1,
            duration: 0.5,
            ease: "easeOut",
            type: "spring",
            stiffness: 80,
            damping: 10,
          }}
          transform="translate(0, 0) scale(1, -1)"
          {...rect}
        />
      ))}
<defs>
        <linearGradient id="paint0" x1="13" y1="36" x2="13" y2="53" gradientUnits="userSpaceOnUse">
          <stop stopColor="#838383" />
          <stop offset="1" stopColor="#454545" />
        </linearGradient>
        <linearGradient id="paint1" x1="31" y1="28" x2="31" y2="61" gradientUnits="userSpaceOnUse">
          <stop stopColor="#838383" />
          <stop offset="1" stopColor="#454545" />
        </linearGradient>
        <linearGradient id="paint2" x1="49" y1="23" x2="49" y2="66" gradientUnits="userSpaceOnUse">
          <stop stopColor="#838383" />
          <stop offset="1" stopColor="#454545" />
        </linearGradient>
        <linearGradient id="paint3" x1="67" y1="33" x2="67" y2="56" gradientUnits="userSpaceOnUse">
          <stop stopColor="#838383" />
          <stop offset="1" stopColor="#454545" />
        </linearGradient>
        <linearGradient id="paint4" x1="85" y1="17" x2="85" y2="72" gradientUnits="userSpaceOnUse">
          <stop stopColor="#838383" />
          <stop offset="1" stopColor="#454545" />
        </linearGradient>
        <linearGradient id="paint5" x1="103" y1="33" x2="103" y2="56" gradientUnits="userSpaceOnUse">
          <stop stopColor="#838383" />
          <stop offset="1" stopColor="#454545" />
        </linearGradient>
        <linearGradient id="paint6" x1="121" y1="23" x2="121" y2="66" gradientUnits="userSpaceOnUse">
          <stop stopColor="#838383" />
          <stop offset="1" stopColor="#454545" />
        </linearGradient>
        <linearGradient id="paint7" x1="139" y1="9" x2="139" y2="80" gradientUnits="userSpaceOnUse">
          <stop stopColor="#838383" />
          <stop offset="1" stopColor="#454545" />
        </linearGradient>
        <linearGradient id="paint8" x1="157" y1="0" x2="157" y2="89" gradientUnits="userSpaceOnUse">
          <stop stopColor="#838383" />
          <stop offset="1" stopColor="#454545" />
        </linearGradient>
        <linearGradient id="paint9" x1="175" y1="28" x2="175" y2="61" gradientUnits="userSpaceOnUse">
          <stop stopColor="#838383" />
          <stop offset="1" stopColor="#454545" />
        </linearGradient>
        <linearGradient
          id="paint10"
          x1="193"
          y1="23"
          x2="193"
          y2="66"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#838383" />
          <stop offset="1" stopColor="#454545" />
        </linearGradient>
        <linearGradient
          id="paint11"
          x1="211"
          y1="33"
          x2="211"
          y2="56"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#838383" />
          <stop offset="1" stopColor="#454545" />
        </linearGradient>
        <linearGradient
          id="paint12"
          x1="229"
          y1="17"
          x2="229"
          y2="72"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#838383" />
          <stop offset="1" stopColor="#454545" />
        </linearGradient>
        <linearGradient
          id="paint13"
          x1="247"
          y1="36"
          x2="247"
          y2="53"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#838383" />
          <stop offset="1" stopColor="#454545" />
        </linearGradient>
        <linearGradient
          id="paint14"
          x1="265"
          y1="40"
          x2="265"
          y2="49"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#838383" />
          <stop offset="1" stopColor="#454545" />
        </linearGradient>
      </defs>
    </svg>
  );
}