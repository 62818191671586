import { Grid2, Stack } from "@mui/material";
import FeatureContainer from "components/FeatureContainer";
import FeatureHeader from "components/Text/FeatureHeader";
import NormalText from "components/Text/NormalText";
import React, { useState } from "react";
import AIBlock from "components/AIBlock";
import Cursor from "components/Cursor";
import "page.css";

export default function MarkdownEditor() {

  const [currentBlock, setCurrentBlock] = useState(0);

  return (
    <FeatureContainer>
      <Grid2 container spacing={6}>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Stack 
            spacing={2} 
            padding={2} 
            className="page"
          >
             <div 
              style={{
                position: "absolute",
                right: "-2px",
                top: "-2px",
                borderLeft: "1px solid white",
                borderBottom: "1px solid white",
                width: "50px",
                height: "50px",
              }}
            /> 
            <NormalText variant="h6">Business Model</NormalText>
            <NormalText fontWeight="bold">Values</NormalText>
            <AIBlock
              animated={false}
            >
              Write the values section with the following values: authenticity,
              cultural immersion, quality, and consistency.
            </AIBlock>
            <NormalText fontWeight="bold">Mission Statement</NormalText>
            <NormalText>
              At Taiwan Corner, we're dedicated to sharing the authentic taste
              and cultural richness of Taiwanese street food. Driven by a vision
              for cultural exchange, we're not just selling food – we're taking
              our customers to Taiwan without having to leave their city.
            </NormalText>
            <NormalText fontWeight="bold">Proposed concept</NormalText>
            <AIBlock
              blockIndex={0}
              currentBlock={currentBlock}
              setCurrentBlock={setCurrentBlock}
              animated={true}
              result={`
              We envision a small and cozy space where people can easily grab a street bite the go. 
              Just as in Taiwan, we see our shop more as a place where people go when they have a craving and can quickly 
              pass by and get a bite. We want to focus on takeaway orders. 
              This reduces the amount of real-estate needed and thus the operation costs.

              ...
              `}
            >
              Expand on the mission statement write a poposed concept.
            </AIBlock>
            <Cursor />
          </Stack>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Stack spacing={2}>
            <FeatureHeader>AI Text Editor</FeatureHeader>
            <NormalText>
              Write faster than ever without losing your personal style using
              our markdown text editor.
            </NormalText>
            <NormalText>
              Combine your content with AI blocks with intructions for the AI to
              generate parts of the content.
            </NormalText>
          </Stack>
        </Grid2>
      </Grid2>
    </FeatureContainer>
  );
}
