import "App.css";
import Header from "components/Header";
import { COLORS } from "colors";
import { Container, Stack } from "@mui/material";
import Title from "components/TitleSection";
import FeaturesHeader from "components/FeaturesHeader";
import FeaturesSection from "components/FeaturesSection";
import TitleSection from "components/TitleSection";
import ModelsSections from "components/ModelsSection";
import Bottom from "components/Bottom";
import BenefitsSection from "components/BenefitsSection";
import heroImage from "svg/heroImage.svg";
import PricingSection from "components/PricingSection";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Stack
        style={{
          backgroundColor: COLORS.black,
          position: "relative",
        }}
      >
        <img
          alt="hero"
          src={heroImage}
          style={{
            width: "100vw",
            position: "absolute",
          }}
        />
        <Container
          style={{
            //backgroundColor: COLORS.black,
            overflowY: "auto",
            overflowX: "none",
            position: "relative",
          }}
        >
          <Stack spacing={16}>
            <Header />
            <TitleSection />
            <PricingSection />
            <FeaturesSection />
            <ModelsSections />
            <BenefitsSection />
            <Bottom />
          </Stack>
        </Container>
      </Stack>
    </BrowserRouter>
  );
}

export default App;
