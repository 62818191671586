import React from "react";
import "cursor.css";

export default function Cursor() {
  return (
    <div
      className="cursor"
    />
  );
}
