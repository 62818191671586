import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import NormalText from "./NormalText";

export default function TypingText({
  children,
  blockIndex,
  speed = 10,
  currentBlock,
  setCurrentBlock,
}) {
  const text = children.toString();
  const [displayedText, setDisplayedText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isInView, setIsInView] = useState(false);

  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      {
        threshold: 0.1, // Adjust this value as necessary; 0.1 means 10% should be visible
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);

  useEffect(() => {
    if (!isInView || blockIndex !== currentBlock) {
      return;
    }
    if (currentIndex < text.length) {
      const timeoutId = setTimeout(() => {
        setDisplayedText((prev) => prev + text[currentIndex]);
        setCurrentIndex((prev) => prev + 1);
      }, speed);
      return () => clearTimeout(timeoutId);
    } else {
      setCurrentBlock(blockIndex + 1);
    }
  }, [blockIndex, currentBlock, currentIndex, text, speed, isInView]);

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: isInView ? 1 : 0 }}
      transition={{ duration: 0.5 }}
    >
      <NormalText>{displayedText}</NormalText>
    </motion.div>
  );
}
