import React from "react";
import Logo from "components/Logo";
import { Button, Stack } from "@mui/material";
import { openApp } from "utils";
import letsGoButton from "svg/letsGoButton.svg";
import { useSearchParams } from "react-router-dom";

export default function Header() {

  const [searchParams] = useSearchParams();
  const model = searchParams.get("model"); 

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{ 
        height: "84px",
        position: "sticky",
        top: 0,
        left: 0,
      }}
      alignItems="center"
    >
      <Logo />
      <img 
        alt="let's go button" 
        src={letsGoButton} 
        style={{
          cursor: "pointer",
          width: "97px",
        }}
        onClick={() => openApp(model)}
      />
    </Stack>
  );

}
