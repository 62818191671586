import { Typography } from "@mui/material";
import { COLORS } from "colors";
import { FONTS } from "fonts";
import React from "react";

export default function HeaderText({children, ...props}) {
  return (
    <Typography
      fontFamily={FONTS.fjalla}
      fontSize="2.5rem"
      color={COLORS.white}
      {...props}
    >
      {children}
    </Typography>
  )
}
