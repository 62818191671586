import { Stack } from "@mui/material";
import ChatMessage from "components/ChatMessage";
import FeatureContainer from "components/FeatureContainer";
import HeaderText from "components/Text/HeaderText";
import NormalText from "components/Text/NormalText";
import React, { useEffect } from "react";
import txtIcon from "svg/txt.svg";
import audioDecoration from "svg/audio-deco.svg";
import { motion, useInView, useAnimation } from "framer-motion";
import SoundWave from "./SoundWave";

const messageVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

export default function AudioSupport() {
  const ref = React.useRef(null);
  const inView = useInView(ref, { threshold: 0.5 }); // Adjust threshold as needed
  const animationControls = useAnimation();

  useEffect(() => {
    if (inView) {
      animationControls.start("visible");
    } else {
      animationControls.start("hidden");
    }
  }, [inView, animationControls]);

  return (
    <FeatureContainer
      sx={{
        position: "relative",
        height: "512px",
      }}
    >
      <Stack
        spacing={2}
        sx={{
          position: "relative",
          height: "100%",
        }}
      >
        <HeaderText>Audio Transcription</HeaderText>
        <NormalText>
          Whether you want to chat using voice, get a transcript of your audio
          conversation or have the AI transform your text into audio, we've got
          you covered.
        </NormalText>

        <Stack
          spacing={3}
          justifyContent="center"
          sx={{
            flexGrow: 1,
          }}
          ref={ref}
        >
          <motion.div
            initial="hidden"
            animate={animationControls}
            variants={messageVariants}
            transition={{ duration: 0.5 }}
          >
            <Stack direction="row" justifyContent="flex-end">
              <ChatMessage
                icon={txtIcon}
                sx={{
                  alignSelf: "flex-end",
                }}
              >
                Read this text out loud for me.
              </ChatMessage>
            </Stack>
          </motion.div>
          <SoundWave />
        </Stack>
      </Stack>
      <img
        alt="decoration"
        src={audioDecoration}
        style={{
          position: "absolute",
          right: "0px",
          bottom: "0px",
        }}
      />
    </FeatureContainer>
  );
}
