import { Typography } from "@mui/material";
import { COLORS } from "colors";
import { FONTS } from "fonts";
import React from "react";

export default function FeatureHeader({ children }) {
  return (
    <Typography
      fontFamily={FONTS.fjalla}
      fontSize="2.5rem"
      color={COLORS.white}
    >
      {children}
    </Typography>
  );
}
