import { Stack, Typography } from "@mui/material";
import { COLORS } from "colors";
import { FONTS } from "fonts";
import React from "react";
import benefits from "svg/benefits.svg";
import NormalText from "./Text/NormalText";
import HeaderText from "./Text/HeaderText";

export default function BenefitsHeader() {
  return (
    <Stack alignItems="center" spacing={1}>
      <img
        alt="benefits"
        src={benefits}
        style={{ width: "99px", height: "40px" }}
      />
      <HeaderText
        align="center"
        sx={{
          background: "linear-gradient(180deg, #FFFFFF, 75%, #555555)",
          backgroundClip: "text",
          fontSize: "3rem",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          color: "transparent",
        }}
      >
        No commitment, all in one AI platform
      </HeaderText>
      <NormalText
        sx={{
          maxWidth: "sm",
        }}
        align="center"
      >
        Tailor your AI experience to your needs. Use your favorite models,
        customize the AI bots to perform specific tasks, write documents,
        analize files. All in one platform.
      </NormalText>
    </Stack>
  );
}
