import { Grid2, Stack } from "@mui/material";
import React from "react";
import FeaturesHeader from "components/FeaturesHeader";
import FeatureContainer from "components/FeatureContainer";
import CreateBot from "./Features/CreateBot";
import FileSupport from "./Features/FileSupport";
import AudioSupport from "./Features/AudioSupport";
import MarkdownEditor from "./Features/MarkdownEditor";
import CustomTools from "./Features/CustomTools";

export default function FeaturesSection() {
  return (
    <Stack
      spacing={6}
    >
      <FeaturesHeader />
      <CreateBot />
      <Grid2 container spacing={6}>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <FileSupport />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <AudioSupport />
        </Grid2>
      </Grid2>
      <MarkdownEditor />
      <CustomTools />
    </Stack>
  )
}
