import { Grid2, Stack } from "@mui/material";
import React from "react";
import FeaturesHeader from "components/FeaturesHeader";
import FeatureContainer from "components/FeatureContainer";
import CreateBot from "./Features/CreateBot";
import FileSupport from "./Features/FileSupport";
import AudioSupport from "./Features/AudioSupport";
import MarkdownEditor from "./Features/MarkdownEditor";
import CustomTools from "./Features/CustomTools";
import ModelsHeader from "./ModelsHeader";

export default function ModelsSections() {
  return (
    <Stack
      spacing={8}
    >
      <ModelsHeader />
     
    </Stack>
  )
}
