import { Stack } from "@mui/material";
import React from "react";
import benefit from "svg/benefit.svg";
import HeaderText from "./Text/HeaderText";
import NormalText from "./Text/NormalText";

export default function Benefit({ title, text, text2 = "" }) {
  return (
    <Stack
      spacing={2}
    >
      <img alt="Benefit header" src={benefit} />
      <HeaderText
        fontSize="1.5rem"
      >
        {title}
      </HeaderText>
      <NormalText>
        {text}
      </NormalText>
      <NormalText>
        {text2}
      </NormalText>
    </Stack>
  );
}
