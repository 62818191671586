import { Grid2, Stack } from "@mui/material";
import React from "react";
import { motion, useInView } from "framer-motion";
import BenefitsHeader from "./BenefitsHeader";
import Benefit from "./Benefit";

const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.5, // delay between each child appearing
      delayChildren: 0.5,   // delay before the first child starts animating
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

function AnimatedBenefit({ title, text, delay = 0}) {
  const ref = React.useRef(null);
  const inView = useInView(ref, { triggerOnce: true });

  return (
    <motion.div
      ref={ref}
      variants={itemVariants}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      transition={{ duration: 0.5, delay, }}
    >
      <Benefit title={title} text={text} />
    </motion.div>
  );
}

export default function BenefitsSection() {
  const ref = React.useRef(null);
  const inView = useInView(ref, { triggerOnce: true });

  return (
    <Stack spacing={8}>
      <BenefitsHeader />
      <motion.div
        ref={ref}
        variants={containerVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
      >
        <Grid2 container spacing={6}>
          <Grid2 size={{ xs: 12, md: 4 }}>
            <AnimatedBenefit
              delay={0}
              title="All in one AI platform"
              text={`
                Benefit from the convenience of accessing cutting-edge AI models all within a single app.
                With each model excelling in different tasks, our platform ensures you can effortlessly use the most suitable 
                AI for your specific needs, without the hassle of switching between applications.  
              `}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 4 }}>
            <AnimatedBenefit
              delay={0.5}
              title="Customizable"
              text={`
                Effortlessly create customized bots by inputting your unique instructions or data. 
                Assemble a dedicated team of AI experts tailored to address each specific challenge, allowing you to 
                engage with the perfect bot for every issue at hand.
              `}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 4 }}>
            <AnimatedBenefit
              delay={1}
              title="Create your own editing tools"
              text={`
                Our AI text editor is fully customizable and lets you add AI tools to transform text. 
                Need a proofreading tool? 
                No problem, build one in seconds with a simple prompt. 
                Need to make your text flow more naturally? 
                Create a bot to help you do that with the click of a button.
              `}
            />
          </Grid2>
        </Grid2>
      </motion.div>
    </Stack>
  );
}