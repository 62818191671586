import { Typography } from "@mui/material";
import { COLORS } from "colors";
import { FONTS } from "fonts";
import React from "react";

export default function NormalText({children, ...props}) {
  return (
    <Typography
      fontFamily={FONTS.geist}
      color={COLORS.white}
      {...props}
    >
      {children}
    </Typography>
  );
}
