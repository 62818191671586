import { Stack, Typography } from "@mui/material";
import React from "react";
import Logo from "./Logo";
import { FONTS } from "fonts";
import bottomBg from "svg/bottom-bg.svg";

export default function Bottom() {
  return (
    <Stack>
      <Stack
        direction="row"
        sx={{
          height: "208px",
          position: "relative",
          overflow: "hidden",
        }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Logo />
        <Typography fontFamily={FONTS.geist} color="#A0A3A2">
          © 2025 CheapGPT All rights reserved.
        </Typography>
        <img
          alt="Bottom decoration"
          src={bottomBg}
          style={{
            width: "100%",
            position: "absolute",
            top: "-0px",
          }}
        />
      </Stack>
    </Stack>
  );
}
