import React from "react";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import { db } from "firebaseApp";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { FONTS } from "fonts";

export default function Promotion() {
  const [signUpGrantData, loading, error] = useDocumentDataOnce(
    doc(db, "creditGrants", "signUp")
  );

  if (error) {
    console.log(error);
  }

  if(loading) {
    return <CircularProgress />
  }

  if (loading || !signUpGrantData || signUpGrantData.remaining <= 0)
    return null;

  return (
    <Stack
      spacing={1}
    >
      <Typography fontFamily={FONTS.geist} color="white" fontSize="1.3rem" textAlign="center">
        We're offering <b>${signUpGrantData.amount}</b> credits to our next{" "}
        {signUpGrantData.remaining} sign-ups!
      </Typography>
      <Typography
        textAlign="center"
        color="white"
      >
        Sounds low? You'll be surprised how far <b>${signUpGrantData.amount}</b> gets you!
      </Typography>
    </Stack>
  );
}
