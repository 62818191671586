import { Button, Stack, Typography } from "@mui/material";
import { COLORS } from "colors";
import { FONTS } from "fonts";
import React from "react";
import NormalText from "./Text/NormalText";
import HeaderText from "./Text/HeaderText";
import { openApp } from "utils";
import primaryButton from "svg/primaryButton.svg";
import Promotion from "./Promotion";

export default function TitleSection() {
  return (
    <Stack p={4} spacing={2}>
      <HeaderText>Your subscription-free door to AI</HeaderText>
      <NormalText
        sx={{
          maxWidth: "sm",
        }}
      >
        Unlock the power of AI without the hassle of subscriptions. Enjoy
        seamless conversations with the latest AI models and only pay for the
        messages you send. No commitments, no hidden fees—just pure,
        straightforward AI interaction.
      </NormalText>
      <Stack alignItems="center" justifyContent="center" pt={5} spacing={2}>
        <Promotion />
        <img
          alt="try"
          src={primaryButton}
          style={{
            cursor: "pointer",
            width: "100px",
          }}
          onClick={openApp}
        />
      </Stack>
    </Stack>
  );
}
